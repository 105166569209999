<template>
  <div class="modal-backdrop fade show" bis_skin_checked="1"></div>
  <div
    class="modal fade show"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    bis_skin_checked="1"
    style="display: block; padding-left: 0px"
    aria-modal="true"
    role="dialog"
  >
    <div class="modal-dialog modal-content">
      <div class="modal-header" bis_skin_checked="1">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="closePopup"
        />
      </div>
      <product-form
        :product="{}"
        :submitFunction="addProduct"
        action-type="new"
        :cancelFunction="cancelFunction"
      />
    </div>
  </div>
</template>

<script>
import ProductForm from "@/components/product/ProductForm";
export default {
  name: "AddProductModal",
  components: { ProductForm },
  data() {
    return {
      customer: {},
    };
  },
  props: {
    closePopup: {
      type: Function,
      required: true,
    },
  },
  computed: {
    buyer() {
      return this.$store.getters.buyer;
    },
  },
  methods: {
    async addProduct(product) {
      await this.$store.dispatch("addProduct", product);
      this.closePopup();
    },
    async cancelFunction() {
      this.closePopup();
    },
  },
};
</script>

<style scoped></style>
