<template>
  <dialogue-modal
    v-if="productToDelete"
    title="Are you sure you want to send it to recovery?"
    :message="productToDelete.name"
    :close-popup="hideDeleteModal"
    :buttons="buttons"
  />
  <add-product-modal v-if="addNewProduct" :close-popup="hideProductModal" />
  <page-title pageTitle="Products" title="All Products" />

  <div class="row pb-4 gy-3">
    <button
      @click="showProductModal"
      class="btn btn-primary addtax-modal col-sm-auto col-md mx-2"
    >
      <i class="las la-plus me-1"></i> Add New
    </button>

    <button
      class="btn btn-secondary col-sm-auto col-md mx-2"
      @click="exportToExcel"
    >
      <i class="las la-download me-1"></i> Export
    </button>

    <div class="col-md-auto col-md">
      <div class="d-flex input-group">
        <label class="input-group-text" for="startDate"> Date </label>
        <DateInput v-model="date" @input="goToPage(1)" />
      </div>
    </div>

    <div v-if="showStockStatus" class="col-sm-auto col-md">
      <select
        class="form-select"
        id="inputGroupSelect01"
        v-model="stockStatus"
        @change="goToPage(1)"
        placeholder="All"
      >
        <option value="" selected>All</option>
        <option value="minimumLimit">Minimum Limit</option>
        <option value="outOfStock">Out of Stock</option>
      </select>
    </div>

    <div class="col-sm-auto col-md-5">
      <div>
        <div class="search-box">
          <input
            type="text"
            class="form-control"
            id="searchMemberList"
            placeholder="Search for Result"
            v-model="searchQuery"
            @input="goToPage(1)"
          />
          <i class="las la-search search-icon"></i>
        </div>
      </div>
    </div>

    <div class="col-sm-auto">
      <div class="d-flex input-group">
        <label class="input-group-text" for="inputGroupSelect01">Show</label>
        <select
          class="form-select"
          id="resultsPerPage"
          v-model="resultsPerPage"
          @change="goToPage(1)"
        >
          <option
            v-for="option in resultsPerPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div v-if="data.length > 0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <table-component
              :headers="headers"
              :data="formattedData"
              :actions="actions"
              :highlightColumn="highlightColumn"
              :delete-selected="deleteSelectedProducts"
              :offset="(currentPage - 1) * resultsPerPage"
              :recoverable="true"
              @sort="goToPage"
            />
          </div>
        </div>
      </div>
    </div>
    <pagination-component
      :rows-loaded="data.length"
      :current-page="currentPage"
      :num-pages="numPages"
      :total-rows="totalRows"
      :go-to-page="goToPage"
    />
  </div>

  <div v-else class="row">
    <div class="col-xl-12">
      <div class="card">
        <LoadingModal v-if="loading" />
        <div v-else class="card-body">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import TableComponent from "@/components/utils/Table";
import LoadingModalComponent from "@/components/utils/LoadingModal";
import PaginationComponent from "@/components/utils/paginationComponent";
import DialogueModal from "@/components/utils/modals/DialogueModal";
import { exportToExcel } from "@/utils/excelUtils";
import AddProductModal from "@/components/product/AddProductModal";
import DateInput from "@/components/utils/DateInput";

export default {
  name: "AllProductsView",
  components: {
    DateInput,
    AddProductModal,
    DialogueModal,
    PaginationComponent,
    PageTitle: PageTitleComponent,
    TableComponent: TableComponent,
    LoadingModal: LoadingModalComponent,
  },
  data() {
    return {
      date: new Date().toISOString().split("T")[0],
      stockStatus: "",
      searchQuery: "",
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
      productToDelete: null,
      addNewProduct: false,
    };
  },
  computed: {
    showStockStatus() {
      return this.$store.getters.settings["Show Stock Status"];
    },
    headers() {
      return this.$store.getters.settings["Product Headers"];
    },
    formattedData() {
      const allProducts = this.$store.getters.allProducts;
      return allProducts.map((product) => ({
        ...product,
        costPrice: this.priceFormat(product.costPrice),
        sellingPrice: this.priceFormat(product.sellingPrice),
        suggestedRetailPrice: this.priceFormat(product.suggestedRetailPrice),
      }));
    },
    data() {
      return this.$store.getters.allProducts;
    },
    currentPage() {
      return this.$store.getters.productsCurrentPage;
    },
    numPages() {
      return this.$store.getters.productsNumPages;
    },
    totalRows() {
      return this.$store.getters.productsTotalRows;
    },
    resultsPerPageOptions() {
      return this.$store.getters.settings["Results Per Page Options"];
    },
    actions() {
      return {
        View: (product) => this.viewProduct(product.id),
        Edit: (product) => this.editProduct(product.id),
        Delete: (product) => this.showDeleteModal(product),
      };
    },
    error() {
      return this.$store.getters.productsError;
    },
    loading() {
      return this.$store.getters.productsLoading;
    },
    buttons() {
      return {
        Yes: {
          class: "btn-danger",
          function: () => this.deleteProduct(),
        },
        No: {
          class: "btn-light px-3",
          function: () => this.hideDeleteModal(),
        },
      };
    },
  },
  methods: {
    priceFormat(price) {
      return `$${price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`.replace("$-", "-$");
    },
    async goToPage(page, sortBy = null) {
      let filter = {
        sortingList: sortBy,
        currentPage: page,
        date: this.date,
        resultsPerPage:
          this.resultsPerPage === "ALL" ? this.totalRows : this.resultsPerPage,
        stockStatus: this.stockStatus,
        searchQuery: this.searchQuery,
      };
      await this.$store.dispatch("getProducts", filter);
    },
    highlightColumn(product) {
      if (this.showStockStatus) {
        return {
          "table-warning":
            product.minimumLimit > product.quantityAvailable &&
            product.quantityAvailable > 0,
          "table-danger": product.quantityAvailable === 0,
        };
      }
    },
    viewProduct(id) {
      this.$router.push("/products/" + id);
    },
    editProduct(id) {
      this.$router.push("/products/edit/" + id);
    },
    showDeleteModal(product) {
      this.productToDelete = product;
    },
    async deleteProduct() {
      if (this.productToDelete) {
        await this.$store.dispatch("deleteProduct", this.productToDelete.id);
        await this.goToPage(1);
        this.hideDeleteModal();
      } else {
        alert("There has been an error");
      }
    },
    async deleteSelectedProducts(products) {
      await this.$store.dispatch("deleteSelectedProducts", products);
      await this.goToPage(1);
    },
    hideDeleteModal() {
      this.productToDelete = null;
    },
    showProductModal() {
      this.addNewProduct = true;
    },
    hideProductModal() {
      this.addNewProduct = false;
      this.goToPage(1);
    },
    exportToExcel() {
      exportToExcel(
        this.data,
        "Products Summary",
        "products_summary.xlsx",
        this.headers
      );
    },
  },
  async created() {
    let filter = {
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
      date: this.date,
    };
    await this.$store.dispatch("getProducts", filter);
  },
  async beforeUnmount() {
    await this.$store.dispatch("resetProducts");
  },
};
</script>

<style scoped></style>
