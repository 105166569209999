<template>
  <add-payment-modal
    v-if="showAddPaymentModal"
    :invoice-summary="invoiceSelected"
    :close-popup="hidePaymentModal"
  />
  <dialogue-modal
    v-if="showDelete"
    title="Are you sure you want to delete?"
    :message="invoiceSelected.invoiceNo"
    :close-popup="hideDeleteModal"
    :buttons="buttons"
  />
  <page-title :pageTitle="pageName" :title="`All ${pageName}`" />

  <div class="row pb-4 gy-3 justify-content-between">
    <div class="col-sm-auto col-md">
      <div class="d-flex input-group">
        <label class="input-group-text" for="startDate"> Start Date </label>
        <DateInput v-model="startDate" @input="goToPage(1)" />
      </div>
    </div>

    <div class="col-sm-auto col-md">
      <div class="d-flex input-group">
        <label class="input-group-text" for="endDate"> End Date </label>
        <DateInput v-model="endDate" @input="goToPage(1)" />
      </div>
    </div>

    <div class="col-sm-auto col-md">
      <div class="input-group">
        <label class="input-group-text" for="inputGroupSelect01">Biller</label>
        <select
          class="form-select"
          id="inputGroupSelect01"
          v-model="biller"
          @change="goToPage(1)"
          placeholder="All"
        >
          <option value="" selected>All</option>
          <option
            v-for="(value, key) in billers"
            :key="key"
            :value="value.company"
          >
            {{ value.company }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="row pb-4 gy-3 justify-content-between">
    <div class="col-sm-auto col-md">
      <div class="input-group">
        <label class="input-group-text" for="inputGroupMonth">Month</label>
        <select
          class="form-select"
          id="inputGroupMonth"
          v-model="this.filterMonth"
          @change="goToPage(1)"
          placeholder="Choose..."
        >
          <option value="">Select a month...</option>
          <option
            v-for="month in months"
            :key="month.value"
            :value="month.value"
          >
            {{ month.label }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-sm-auto col-md">
      <div class="input-group">
        <label class="input-group-text" for="inputGroupYear">Year</label>
        <input
          type="text"
          class="form-control"
          id="inputGroupYear"
          v-model="this.filterYear"
          @change="goToPage(1)"
          placeholder="Year"
        />
      </div>
    </div>

    <div
      class="col-sm-auto col-md"
      v-if="!$store.getters.settings['Custom Status']"
    >
      <div class="input-group">
        <label class="input-group-text" for="inputGroupSelect01">Status</label>
        <select
          class="form-select"
          id="inputGroupSelect01"
          v-model="this.status"
          @change="goToPage(1)"
          placeholder="Choose..."
        >
          <option value="" selected>All</option>
          <option class="text-bg-danger" value="DUE">DUE</option>
          <option class="text-bg-primary" value="PAID">PAID</option>
          <option class="text-bg-warning" value="REFUND">REFUND</option>
        </select>
      </div>
    </div>

    <div class="col-sm-auto col-md" v-else>
      <div class="input-group">
        <label class="input-group-text" for="inputGroupSelect01">Status</label>
        <select
          class="form-select"
          id="inputGroupSelect01"
          v-model="this.customStatus"
          @change="goToPage(1)"
          placeholder="Choose..."
        >
          <option value="" selected>All</option>
          <option
            v-for="(status, index) in $store.getters.settings['Custom Status']"
            :key="index"
            :value="status"
          >
            {{ status }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="row pb-4 gy-3 justify-content-between">
    <div class="col-sm-auto col-md">
      <router-link
        to="/invoices/new"
        class="btn btn-primary addtax-modal no-wrap"
      >
        <i class="las la-plus me-1"></i> Add New
      </router-link>
    </div>

    <div class="col-sm-auto col-md-8">
      <div class="search-box">
        <input
          type="text"
          class="form-control"
          id="searchMemberList"
          placeholder="Search for Result"
          v-model="searchQuery"
          @input="goToPage(1)"
        />
        <i class="las la-search search-icon"></i>
      </div>
    </div>

    <div class="col-sm-auto col-md">
      <div class="input-group">
        <label class="input-group-text" for="inputGroupSelect01">Show</label>
        <select
          class="form-select"
          id="resultsPerPage"
          v-model="resultsPerPage"
          @change="goToPage(1)"
        >
          <option
            v-for="option in resultsPerPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div v-if="data && data.length > 0" class="">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <invoices-table
              :headers="headers"
              :data="formattedData"
              :actions="actions"
              :offset="(currentPage - 1) * resultsPerPage"
              @sort="goToPage"
            />
          </div>
        </div>
      </div>
    </div>
    <pagination-component
      :rows-loaded="data.length"
      :current-page="currentPage"
      :num-pages="numPages"
      :total-rows="totalRows"
      :go-to-page="goToPage"
    />
  </div>

  <div v-else class="row">
    <div class="col-xl-12">
      <div class="card">
        <LoadingModal v-if="loading" />
        <div v-else class="card-body">
          {{ error }}
        </div>
        <div class="gap-2 mt-4 card-body">
          <button
            type="button"
            class="btn btn-light"
            @click="this.$router.go(-1)"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import { CONFIG } from "@/constants/config.js";
import LoadingModalComponent from "@/components/utils/LoadingModal";
import PaginationComponent from "@/components/utils/paginationComponent";
import DialogueModal from "@/components/utils/modals/DialogueModal";
import InvoicesTable from "@/components/invoice/InvoicesTable";
import AddPaymentModal from "@/components/payment/AddPaymentModal";
import DateInput from "@/components/utils/DateInput";

export default {
  name: "AllInvoicesView",
  components: {
    DateInput,
    AddPaymentModal,
    InvoicesTable,
    DialogueModal,
    PaginationComponent,
    LoadingModal: LoadingModalComponent,
    PageTitle: PageTitleComponent,
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      filterMonth: "",
      filterYear: "",
      status: "",
      customStatus: "",
      biller: "",
      searchQuery: "",
      months: [
        { value: "1", label: "January" },
        { value: "2", label: "February" },
        { value: "3", label: "March" },
        { value: "4", label: "April" },
        { value: "5", label: "May" },
        { value: "6", label: "June" },
        { value: "7", label: "July" },
        { value: "8", label: "August" },
        { value: "9", label: "September" },
        { value: "10", label: "October" },
        { value: "11", label: "November" },
        { value: "12", label: "December" },
      ],
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
      invoiceSelected: null,
      showDelete: false,
      showAddPaymentModal: false,
    };
  },
  computed: {
    pageName() {
      return this.$store.getters.settings["Invoice Page Name"];
    },
    BACKEND_URL() {
      return CONFIG.BACKEND_URL;
    },
    headers() {
      return this.$store.getters.settings["Invoice Headers"];
    },
    data() {
      return this.$store.getters.allInvoices;
    },
    formattedData() {
      const allInvoices = this.$store.getters.allInvoices;
      return allInvoices.map((invoice) => ({
        ...invoice,
        date: this.dateFormat(invoice.date),
        total: this.priceFormat(invoice.total),
        paid: this.priceFormat(invoice.paid),
        discountRedeemed: this.priceFormat(invoice.discountRedeemed),
        balance: this.priceFormat(invoice.balance),
      }));
    },
    billers() {
      return this.$store.getters.allBillers;
    },
    totalCount() {
      if (!this.data || this.data.length === 0) {
        return 0;
      }
      return this.priceFormat(
        this.data
          .reduce((total, item) => total + parseFloat(item.total), 0)
          .toFixed(2)
      );
    },
    paidCount() {
      if (!this.data || this.data.length === 0) {
        return 0;
      }
      return this.priceFormat(
        this.data
          .reduce((total, item) => total + parseFloat(item.paid), 0)
          .toFixed(2)
      );
    },
    discountCount() {
      if (!this.data || this.data.length === 0) {
        return 0;
      }
      return this.priceFormat(
        this.data
          .reduce((total, item) => total + parseFloat(item.discountRedeemed), 0)
          .toFixed(2)
      );
    },
    balanceCount() {
      if (!this.data || this.data.length === 0) {
        return 0;
      }
      return this.priceFormat(
        this.data
          .reduce((total, item) => total + parseFloat(item.balance), 0)
          .toFixed(2)
      );
    },
    creditCount() {
      if (!this.data || this.data.length === 0) {
        return 0;
      }
      return this.priceFormat(
        this.data
          .reduce((total, item) => total + parseFloat(item.amountCredited), 0)
          .toFixed(2)
      );
    },
    currentPage() {
      return this.$store.getters.invoicesCurrentPage;
    },
    numPages() {
      return this.$store.getters.invoicesNumPages;
    },
    totalRows() {
      return this.$store.getters.invoicesTotalRows;
    },
    resultsPerPageOptions() {
      return this.$store.getters.settings["Results Per Page Options"];
    },
    actions() {
      let allActions = {
        View: (invoice) => this.viewInvoice(invoice.id),
        Edit: (invoice) => this.editInvoice(invoice.id),
        "Add Payment": (invoice) => this.showPaymentModal(invoice),
        "View Payments": (invoice) => this.viewPayments(invoice.id),
        "Email Invoice": (invoice) => this.emailPDF(invoice.id),
        "Email EDI": (invoice) => this.emailEDI(invoice.id),
        "Print Invoice": (invoice) => this.printInvoice(invoice.id),
        "Print Thermal": (invoice) => this.printThermal(invoice.id),
        "EDI MS": (invoice) => this.ediMS(invoice.id),
        "EDI C": (invoice) => this.ediC(invoice.id),
        Transfer: (invoice) =>
          this.transferInvoice(invoice.id, invoice.billerID),
        Delete: (invoice) => this.showDeleteModal(invoice),
      };

      // Filter actions based on the settings
      let availableActions =
        this.$store.getters.settings["Invoice Actions Dropdown"];
      let filteredActions = Object.keys(allActions)
        .filter((action) => availableActions.includes(action))
        .reduce((obj, action) => {
          obj[action] = allActions[action];
          return obj;
        }, {});

      return filteredActions;
    },
    error() {
      return this.$store.getters.invoicesError;
    },
    loading() {
      return this.$store.getters.invoicesLoading;
    },
    buttons() {
      return {
        Yes: {
          class: "btn-danger",
          function: () => this.deleteInvoice(),
        },
        No: {
          class: "btn-light px-3",
          function: () => this.hideDeleteModal(),
        },
      };
    },
  },
  methods: {
    dateFormat(date) {
      let parts = date.split("-");
      let formattedDate = parts[1] + "-" + parts[2] + "-" + parts[0];
      return formattedDate;
    },
    priceFormat(price) {
      return `$${price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`.replace("$-", "-$");
    },
    async goToPage(page, sortBy = null) {
      let filter = {
        sortingList: sortBy,
        currentPage: page,
        resultsPerPage:
          this.resultsPerPage === "ALL" ? this.totalRows : this.resultsPerPage,
        startDate: this.startDate,
        endDate: this.endDate,
        biller: this.biller,
        month: this.filterMonth,
        year: this.filterYear,
        status: this.status,
        customStatus: this.customStatus,
        searchQuery: this.searchQuery,
      };
      await this.$store.dispatch("getInvoices", filter);
      await this.$store.dispatch("getBillers");
    },
    viewInvoice(id) {
      this.$router.push("/invoices/" + id);
    },
    viewPayments(id) {
      this.$router.push("/payment/invoice/" + id);
    },
    editInvoice(id) {
      if (id > 1) {
        this.$router.push("/invoices/edit/" + id);
      } else {
        //Notification Alert
        alert("You can't edit default price group");
      }
    },
    printInvoice(id) {
      this.$router.push("/invoices/print/" + id);
    },
    printThermal(id) {
      this.$router.push("/invoices/thermalPrint/" + id);
    },
    ediMS(id) {
      window.location.href = `${this.BACKEND_URL}invoice/edi/modisoft/${id}/`;
    },
    ediC(id) {
      window.location.href = `${this.BACKEND_URL}invoice/edi/cstore/${id}/`;
    },
    emailPDF(id) {
      this.$router.push("/invoices/emailPDF/" + id);
    },
    async emailEDI(id) {
      await this.$store.dispatch("emailEDI", id);
    },
    async transferInvoice(invoiceID, billerID) {
      let id = (billerID % 2) + 1;
      await this.$store.dispatch("editInvoice", {
        id: invoiceID,
        billerID: id,
      });
      await this.goToPage(1);
    },
    showPaymentModal(invoice) {
      this.invoiceSelected = this.data.find((item) => item.id === invoice.id);
      this.showAddPaymentModal = true;
    },
    hidePaymentModal() {
      this.invoiceSelected = null;
      this.showAddPaymentModal = false;
    },
    showDeleteModal(invoice) {
      this.invoiceSelected = invoice;
      this.showDelete = true;
    },
    async deleteInvoice() {
      if (this.invoiceSelected) {
        await this.$store.dispatch("deleteInvoice", this.invoiceSelected.id);
        await this.goToPage(1);
        this.hideDeleteModal();
      } else {
        alert("There has been an error");
      }
    },
    hideDeleteModal() {
      this.invoiceSelected = null;
      this.showDelete = false;
    },
    async newInvoice() {
      await this.$store.dispatch("addInvoice");
    },
  },
  async created() {
    let currentDate = new Date();
    let startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 36));
    this.startDate = `${startDate.getFullYear()}-${(
      startDate.getMonth() +
      1 +
      ""
    ).padStart(2, "0")}-${(startDate.getDate() + "").padStart(2, "0")}`;
    let filter = {
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
      startDate: this.startDate,
    };
    await this.$store.dispatch("getInvoices", filter);
    await this.$store.dispatch("getBillers");
  },
  beforeUnmount() {
    console.log("All Invoices Destroyed");
  },
};
</script>
