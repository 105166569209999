<template>
  <dialogue-modal
    title="Do you want to print the invoice?"
    :message="invoiceDetails.invoiceNo"
    :buttons="buttons"
  />
  <loading-modal v-if="loading" />
  <div
    v-else
    :id="invoiceDetails.invoiceNo"
    class="justify-content-center bg-white p-3 vh-100"
  >
    <div
      v-if="invoiceDetails.billerLogo"
      class="text-center"
      style="margin-bottom: 20px"
      bis_skin_checked="1"
    >
      <img
        :src="`/images/logos/${invoiceDetails.billerLogo}`"
        :alt="invoiceDetails.biller"
      />
      <br />
      {{ invoiceDetails.fullBillerAddress }} <br />
      {{ invoiceDetails.billerPhone }}
    </div>
    <div class="row m-1 mb-2 mt-0" style="font-size: 15px" bis_skin_checked="1">
      <div class="col-6" bis_skin_checked="1">
        <p class="mb-2"><strong>To:</strong></p>
        <p class="fs-18 mb-2">
          <strong> {{ invoiceDetails.customer }} </strong>
        </p>
        <p class="mb-2">
          <strong> {{ invoiceDetails.fullCustomerAddress }}</strong>
        </p>
      </div>
      <div class="col"></div>
      <div class="col-2 px-2" bis_skin_checked="1">
        <p class="mb-2 fs-12">
          <strong>
            Date: {{ this.dateFormat(invoiceDetails.date) }}<br />
            <span class="fs-18"> Invoice No: </span>
            <br />
            <span class="fs-18">
              {{ invoiceDetails.invoiceNo.split("Invoice/")[1] }}
            </span>
            <br />
          </strong>
        </p>
      </div>
    </div>

    <div class="table-responsive" bis_skin_checked="1">
      <table
        class="table table-hover print-table order-table"
        style="border-color: darkgrey"
      >
        <thead style="border-color: darkgrey">
          <tr>
            <th>No</th>
            <th>Product Name</th>
            <th>Barcode</th>
            <th>Weight</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, index) in data" :key="index">
            <td style="text-align: center; width: 30px; vertical-align: middle">
              {{ index + 1 }}
            </td>
            <td style="width: 400px; text-align: left; vertical-align: middle">
              {{ row.productName }}
            </td>
            <td
              style="width: 130px; text-align: center; vertical-align: middle"
            >
              <img
                width="115"
                height="32"
                :src="`/images/barcodes/${row.barcode}.png`"
                :alt="row.barcode"
              />
            </td>
            <td style="text-align: right">
              {{ `${row.weight} ${row.weightUnit}` }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row" bis_skin_checked="1">
      <div class="col-xs-12" bis_skin_checked="1"></div>
    </div>
    <div v-if="invoiceDetails.notes" class="mt-4">
      <div class="alert alert-info">
        <p class="mb-0">
          <span class="fw-semibold">Notes For Customer: </span>
          <span id="note">
            {{ invoiceDetails.notes }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingModal from "@/components/utils/LoadingModal";
import DialogueModal from "@/components/utils/modals/DialogueModal";
import { CONFIG } from "@/constants/config";

export default {
  name: "InvoicePrintView.vue",
  components: {
    DialogueModal,
    LoadingModal,
  },
  data() {
    return {
      id: -1,
    };
  },
  computed: {
    data() {
      return this.$store.getters.invoice;
    },
    invoiceDetails() {
      return this.$store.getters.invoiceSummary;
    },
    loading() {
      return !this.invoiceDetails;
    },
    buttons() {
      return {
        Back: {
          class: "btn-light",
          function: () => this.$router.push("/invoices"),
        },
        Print: {
          class: "btn-success px-3",
          function: () => window.print(),
        },
      };
    },
    host() {
      return CONFIG.HOST;
    },
  },
  methods: {
    dateFormat(date) {
      let parts = date.split("-");
      let formattedDate = parts[1] + "-" + parts[2] + "-" + parts[0];
      return formattedDate;
    },
    priceFormat(price) {
      return `$${price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`.replace("$-", "-$");
    },
    percentageFormat(value) {
      return value.toFixed(2) + "%";
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    await this.$store.dispatch("getInvoice", this.id);
    await this.$store.dispatch("getInvoiceItems", {
      id: this.id,
      filter: null,
    });
    window.print();
    // window.onafterprint = () => {
    //   this.$router.go(-1);
    //   window.onafterprint = null;
    // };
  },
  async beforeUnmount() {
    await this.$store.dispatch("resetInvoice");
    await this.$store.dispatch("resetBiller");
    await this.$store.dispatch("resetCustomer");
  },
};
</script>

<style scoped>
@media print {
  table {
    font-size: 11pt;
  }
}
</style>
