<template>
  <div class="row">
    <div class="col-sm-auto col-md">
      <div class="card">
        <div class="card-body">
          <div class="p-2">
            <form :class="{ 'disabled-form': disabled }">
              <div class="row">
                <div class="mb-3">
                  <label for="product" class="form-label"> Product Name </label>
                  <DropdownInput
                    id="product"
                    type="text"
                    placeholder="Enter Product Name"
                    :chosen="{ value: updatedProduct.category }"
                    :options="categories"
                    :disabled="disabled"
                    @choice="setCategory"
                  />
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label" for="weight">Weight</label>
                <div class="d-flex">
                  <div class="flex-grow-1 me-2" style="width: 70%">
                    <input
                      id="weight"
                      placeholder="Enter Weight"
                      v-model="updatedProduct.weight"
                      type="number"
                      step="0.01"
                      class="form-control"
                    />
                  </div>
                  <div style="width: 30%">
                    <select
                      id="weightUnit"
                      v-model="updatedProduct.weightUnit"
                      class="form-control"
                    >
                      <option value="kg">kg</option>
                      <option value="g">g</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label" for="productnumber">
                  Item Barcode Number
                </label>
                <div class="input-group" bis_skin_checked="1">
                  <input
                    v-model="updatedProduct.barcode"
                    type="text"
                    class="form-control"
                    placeholder="Click the button to generate barcode"
                  />
                  <button
                    v-if="!disabled"
                    class="btn btn-success"
                    type="button"
                    @click="generateBarcode"
                  >
                    <i class="ri-refresh-line align-bottom me-1"></i>
                  </button>
                </div>
              </div>
              <div class="hstack gap-2 mt-4">
                <button
                  v-if="!disabled"
                  type="button"
                  @click="submit"
                  class="btn btn-primary"
                >
                  Save
                </button>
                <button
                  v-if="!disabled"
                  type="button"
                  class="btn btn-success"
                  onclick="alert('Printing Barcode')"
                >
                  Print
                </button>
                <button
                  v-if="disabled"
                  type="button"
                  class="btn btn-primary"
                  @click="edit"
                >
                  Edit
                </button>
                <button
                  v-if="cancelFunction"
                  type="button"
                  class="btn btn-light"
                  @click="cancelFunction"
                >
                  Back
                </button>
                <button
                  v-if="!cancelFunction"
                  type="button"
                  class="btn btn-light"
                  @click="discard"
                >
                  Back
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownInput from "@/components/utils/DropdownInput";

export default {
  name: "ProductForm",
  components: { DropdownInput },
  props: {
    product: {
      type: Object,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
    submitFunction: {
      type: Function,
      default: null,
    },
    cancelFunction: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      updatedProduct: {
        ...this.product,
        quantitySold:
          "quantitySold" in this.product && this.product.quantitySold !== null
            ? this.product.quantitySold
            : 0,
        barcodeSymbology:
          "barcodeSymbology" in this.product &&
          this.product.barcodeSymbology !== null
            ? this.product.barcodeSymbology
            : "upca",
        productGroupID:
          "productGroupID" in this.product &&
          this.product.productGroupID !== null
            ? this.product.productGroupID
            : 0,
        weightUnit:
          "weightUnit" in this.product && this.product.weightUnit !== null
            ? this.product.weightUnit
            : "kg",
      },
      chosenCategory: {},
    };
  },
  methods: {
    async submit() {
      if (!this.disabled) {
        this.updatedProduct.category = this.chosenCategory.value;
        this.updatedProduct.name = this.chosenCategory.value;
        this.submitFunction(this.updatedProduct);
      }
    },
    discard() {
      this.updatedProduct = { ...this.product };
      this.$router.push("/products/");
    },
    edit() {
      this.$router.push("/products/edit/" + this.product.id);
    },
    async generateBarcode() {
      await this.$store.dispatch("generateBarcode", this.updatedProduct);
    },
    async setCategory(value, id) {
      let filter = {
        resultsPerPage: this.$store.getters.settings["Results Per Page"],
        searchQuery: value,
      };
      await this.$store.dispatch("getCategories", filter);
      this.chosenCategory.value = value;
      if (id > 0) {
        this.chosenCategory.value = this.categories.find(
          (category) => category.id === id
        );
      }
    },
  },
  computed: {
    categories() {
      return this.$store.getters.categories;
    },
    disabled() {
      return this.actionType === "view";
    },
    barcode() {
      return this.$store.getters.barcode;
    },
  },
  async created() {
    let filter = {
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
    };
    await this.$store.dispatch("getCategories", filter);
  },
};
</script>

<style scoped>
.disabled-form input {
  pointer-events: none;
  opacity: 0.6;
}
</style>
